import { Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService} from "./local-storage.service";
import {BehaviorSubject, Subject} from "rxjs";
import { CommonFareType, FareTypesCategory, PrepareFareTypeInfo } from "../interfaces/fare-types";
import { Dictionary } from "../types/dictionary";
import { Browser } from "../types/browser";
import {AVAILABLE_TITLES, ERROR_CODES} from "../constants";
import {Observable} from "rxjs/internal/Observable";
import moment from "moment";
import {FullMinMaxAgeRange, ProviderAgesScheme} from "../interfaces/date";
import {DateService} from "./date.service";

declare var intlTelInputGlobals: any;


@Injectable()
export class HelpersService {

  maxStopsSubject$ = new Subject<string>();
  nextActiveSelectId$: Subject<string>;
  nextActiveMultiSelectId$: Subject<string>;
  godMode$ = new BehaviorSubject<{isTriggered: boolean; isEnabled: boolean}>({isTriggered: false, isEnabled: false});
  isPrivateMode = false;
  defaultCurrency: string;
  isOrderTicketed = false;
  lastOrderPnr = '';
  presetTitle = '';
  isSuperUser = false;
  isProvidersLoaded = new BehaviorSubject(false);
  isProvidersLoaded$ = this.isProvidersLoaded.asObservable();
  allProviders = [];
  allProvidersObj = {};

  defaultTelInputOptions: any = {
    preferredCountries: ['es', 'de', 'fr', 'ru', 'gb', 'us'],
    separateDialCode: true,
    formatOnDisplay: false,
    customPlaceholder: function (selectedCountryPlaceholder) {
      return selectedCountryPlaceholder.replace(/[\s-]/g, '');
    },
  };

  constructor(
    private router: Router,
    private serializer: UrlSerializer,
    private ls: LocalStorageService,
    private dateService: DateService,
  ) {
    this.nextActiveSelectId$ = new Subject();
    this.nextActiveMultiSelectId$ = new Subject();
  }

  changeMaxStops(maxStops: string) {
    this.maxStopsSubject$.next(maxStops);
  }

  getMaxStops(): Observable<string> {
    return this.maxStopsSubject$.asObservable();
  }

  setGodModeData(isTriggered: boolean, isEnabled: boolean) {
    this.godMode$.next({isTriggered, isEnabled});
  }

  getGodModeData(): Observable<{isTriggered: boolean; isEnabled: boolean}> {
    return this.godMode$.asObservable();
  }

  get isAirgateway() {
    return this.ls.agency === 'airgateway' || this.ls.agency === 'sales';
  }

  public isChromiumBasedBrowser(): boolean {
    const wind = window as any;
    return !!wind.chrome;
  }

  isBrowserSupported(): boolean {
    let isIE = !!document['documentMode'];
    if (isIE) {
      this.ls.removeRequireChromeBrowser();
    }
    return !isIE;
  }

  // format to 2018-09-01
  static getFormattedDate(date: NgbDateStruct) {
    return date && date.year && date.month && date.day
      ? `${date.year}-${this.addZero(date.month)}-${this.addZero(date.day)}`
      : null;
  }

  static addZero(value) {
    if (value && +value < 10) {
      return `0${value}`;
    } else {
      return value;
    }
  }

  public addZero(value) {
    if (value && +value < 10) {
      return `0${value}`;
    } else {
      return value;
    }
  }

  /**
   * format date for datePicker
   * @param date
   * string (2018-09-01)
   */
  formatDateToNgbDateStruct(date: string) {
    const temp = date.split('-');
    return {
      year: +temp[0],
      month: +temp[1],
      day: +temp[2],
    };
  }

  getKeyByValue(obj, value) {
    return Object.keys(obj).find(key => obj[key].toLowerCase() === value.toLowerCase());
  }

  static getStructFromDate(date: Date, utc = true) {
    return <NgbDateStruct>{
      year: utc ? date.getUTCFullYear() : date.getFullYear(),
      month: utc ? date.getUTCMonth() + 1 : date.getMonth() + 1,
      day: utc ? date.getUTCDate() : date.getDate(),
    };
  }

  static createStructWithChangeDays(inDate: NgbDateStruct | Date, days: number = 0, increase: boolean = true) {
    let date: NgbDateStruct;
    if (inDate instanceof Date) {
      date = HelpersService.getStructFromDate(inDate);
    } else {
      date = inDate;
    }
    let lastMinDate = new Date(HelpersService.getFormattedDate(date));
    if (increase) {
      lastMinDate.setDate(lastMinDate.getDate() + days);
    } else {
      lastMinDate.setDate(lastMinDate.getDate() - days);
    }
    return HelpersService.getStructFromDate(lastMinDate);
  }

  static isFirstDateGreater(date1: NgbDateStruct, date2: NgbDateStruct) {
    let date1Str = new Date(date1.year + ',' + date1.month + ',' + date1.day).getTime();
    let date2Str = new Date(date2.year + ',' + date2.month + ',' + date2.day).getTime();

    return date1Str > date2Str;
  }

  formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  go(url) {
    this.router.navigateByUrl(url);
    /*.then((res) => {
          if (res) {
            gtag('event', 'page_view', {'send_to': Dictionary.GTAG_ID});
          }
        });*/
  }

  scrollTo(y, duration) {
    const initialY = document.body.scrollTop;
    const baseY = (initialY + y) * 0.5;
    const difference = initialY - baseY;
    const startTime = performance.now();

    function step() {
      let normalizedTime = (performance.now() - startTime) / duration;
      if (normalizedTime > 1) {
        normalizedTime = 1;
      }

      window.scrollTo(0, baseY + difference * Math.cos(normalizedTime * Math.PI));
      if (normalizedTime < 1) {
        window.requestAnimationFrame(step);
      }
    }

    window.requestAnimationFrame(step);
  }

  getOriginDestinations(flights: any[]) {
    return flights.map((flight, i) => {
      return {
        departure: flight.departure.airportCode,
        departureDate: flight.departure.date,
        departureTime: flight.departure.time,
        arrival: flight.arrival.airportCode,
        stringData: `${flight.departure.airportCode} → ${flight.arrival.airportCode}`,
        isDateInvalid: false,
        id: i
      };
    });
  }

  getError(error = null): any {
    let message = Dictionary.ErrorMessages.invalidResponse;
    let errorCode = error && error.status ? `[${error.status} - ${error.error?.code || 'unknown'}] ` : '';
    if (error && error.error && error.error.detail) {
      let title = this.getErrorTitle(error);
      message = title + error.error.detail;
    }

    message = errorCode + message;

    let type = this.getErrorType(error);

    return {
      message,
      type
    };
  }

  getErrorType(error) {
    if (error && error.error && error.error.group === ERROR_CODES.PROVIDER_ERROR) {
      return 'danger-provider';
    }
    return 'danger';
  }

  getErrorTitle(error) {
    let title = '';
    let splittedTitle = error.error.group?.split('_');
    if (splittedTitle) {
      title = this.capitalizeWords(splittedTitle)?.join(' ') + ': ';
    }
    return title;
  }

  capitalizeWords(arr: string[]) {
    return arr?.map(element => {
      return element.charAt(0).toUpperCase() + element.substring(1).toLowerCase();
    });
  }

  getWarningsFromResponse(res) {
    if (!this.ls.warningsDisabled) {
      let result = [];
      if (res?.warnings && res.warnings.length) {
        res.warnings.map(item => {
          const parsed = item.text.split(', msg:').reverse().join(', ');
          result.push(parsed);
        });
      }
      return result;
    } else {
      return [];
    }
  }

  getRefactoredWarnings(warnings: any[]) {
    let result = [];
    let refactoredWarnings = [];
    if (warnings?.length) {
      const separators = ['short:', 'code:', 'msg:'];
      let temp;
      warnings.map((item, i) => {
        let replacedItem = item.text;
        separators.map(sep => {
          replacedItem = replacedItem.replace(sep, `-/-${sep}`);
        });
        temp = replacedItem.split('-/-').filter(item => item).map(value => value.trim());

        result[i] = {};

        temp.map((item) => {
          separators.map(sep => {
            let idx = item.indexOf(sep);
            if (idx !== -1) {
              const key = sep.replace(':', '');
              result[i][key] = item.substr(idx + sep.length);
            }
          });
        });

        const short = result[i]['short'] || '';
        const code = result[i]['code'] || '';
        const msg = result[i]['msg'] || '';
        let warningMessage = short + code + msg;

        if (this.isNumber(code.replace(/[^a-zA-Z ]/g, ''))) {
          warningMessage = warningMessage.replace(code, '');
        }

        if (this.isNumber(short.replace(/[^a-zA-Z ]/g, ''))) {
          warningMessage = warningMessage.replace(short, '');
        }

        if (!this.isNumber(short.replace(/[^a-zA-Z ]/g, '')) &&
          !this.isNumber(code.replace(/[^a-zA-Z ]/g, '')) &&
          msg === short && code === msg) {
          warningMessage = warningMessage.replace(code, '');
          warningMessage = warningMessage.replace(short, '');
        }

        if (!this.isNumber(short.replace(/[^a-zA-Z ]/g, '')) &&
          !this.isNumber(code.replace(/[^a-zA-Z ]/g, '')) &&
          short === code) {
          warningMessage = warningMessage.replace(code, '');
        }

        if (warningMessage) {
          refactoredWarnings.push(warningMessage);
        }
      });
    }
    return refactoredWarnings;
  }

  removeItemFromArrayIfExist(arr, item: string | number) {
    const index = arr.indexOf(item, 0);
    if (index > -1) {
      arr.splice(index, 1);
    }
  }

  filterArrayDuplicatesByKey(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      for (let i = pos; i < arr.length; i++) {
        if (arr[i][prop] === obj[prop] && i !== pos) {
          return false;
        }
      }
      return true;
    });
  }


  public getDurationInMinutes(offer, leg: number | string = '') {
    let minutes = 0;
    if (leg === '') {
      offer.flights.map((flight) => {
        if (flight.duration) {
          minutes += this.durationParser(flight);
        }
      });
    } else {
      if (offer.flights[leg] && offer.flights[leg].duration) {
        minutes = this.durationParser(offer.flights[leg]);
      }
    }
    return minutes;
  }

  private durationParser(flight) {
    return moment.duration(flight.duration).asMinutes();
  }

  getColonTime(timeInMinutes: number) {
    if (!isNaN(timeInMinutes)) {
      return Math.floor(timeInMinutes / 60).toString().padStart(2, '0') + ':00';
    }

    return '';
  }

  sortOffers(sortStrategy: string, offers: any[], originDestinations: any): any[] {
    const result = offers.sort((a, b) => {
      switch (sortStrategy) {
        case 'default':
          return;
        case 'price_asc':
          return a.price.consumer.total > b.price.consumer.total ? 1 : -1;
        case 'price_desc':
          return a.price.consumer.total > b.price.consumer.total ? -1 : 1;
        case 'shorter_time':
          return this.getDurationInMinutes(a) > this.getDurationInMinutes(b) ? 1 : -1;
        case 'longer_time':
          return this.getDurationInMinutes(a) > this.getDurationInMinutes(b) ? -1 : 1;
        case 'first_flight_dep':
        case 'first_flight_arr':
        case 'last_flight_dep':
        case 'last_flight_arr':
          let fLenA = a.flights.length - 1;
          let fsLenA = a.flights[fLenA].segments.length - 1;
          let firstFlightFsLenA = a.flights[0].segments.length - 1;

          let fLenB = b.flights.length - 1;
          let fsLenB = b.flights[fLenB].segments.length - 1;
          let firstFlightFsLenB = b.flights[0].segments.length - 1;

          let tmpA, tmpB, timeA, timeB;
          let isAddDayA = false, isAddDayB = false;
          switch (sortStrategy) {
            case 'first_flight_dep':
              tmpA = a.flights[0].segments[0].originDestination.departure.time.split(':');
              if (tmpA.length < 2) {
                return 1;
              }
              timeA = parseInt(tmpA[0]) * 60 + parseInt(tmpA[1]);

              tmpB = b.flights[0].segments[0].originDestination.departure.time.split(':');
              if (tmpB.length < 2) {
                return 0;
              }
              timeB = parseInt(tmpB[0]) * 60 + parseInt(tmpB[1]);

              return timeA > timeB ? 1 : -1;
            case 'first_flight_arr':
              tmpA = a.flights[0].segments[firstFlightFsLenA].originDestination.arrival.time.split(':');
              if (tmpA.length < 2) {
                return 1;
              }
              isAddDayA = false;
              if (a.flights.length === 1) {
                isAddDayA = originDestinations[fLenA].departure.date !== a.flights[fLenA].segments[fsLenA].originDestination.arrival.date;
              }
              timeA = parseInt(tmpA[0]) * 60 + parseInt(tmpA[1]) + (isAddDayA ? 24 * 60 : 0);

              tmpB = b.flights[0].segments[firstFlightFsLenB].originDestination.arrival.time.split(':');
              if (tmpB.length < 2) {
                return 0;
              }
              isAddDayB = false;
              if (b.flights.length === 1 && a.flights[fLenB].segments[fsLenB]) {
                isAddDayB = originDestinations[fLenB].departure.date !== a.flights[fLenB].segments[fsLenB].originDestination.arrival.date;
              }
              timeB = parseInt(tmpB[0]) * 60 + parseInt(tmpB[1]) + (isAddDayB ? 24 * 60 : 0);

              return timeA > timeB ? 1 : -1;
            case 'last_flight_dep':
              tmpA = a.flights[fLenA].segments[0].originDestination.departure.time.split(':');
              if (tmpA.length < 2) {
                return 1;
              }
              timeA = parseInt(tmpA[0]) * 60 + parseInt(tmpA[1]);

              tmpB = b.flights[fLenB].segments[0].originDestination.departure.time.split(':');
              if (tmpB.length < 2) {
                return 0;
              }
              timeB = parseInt(tmpB[0]) * 60 + parseInt(tmpB[1]);

              return timeA < timeB ? 1 : -1;
            case 'last_flight_arr':
              tmpA = a.flights[fLenA].segments[fsLenA].originDestination.arrival.time.split(':');
              if (tmpA.length < 2) {
                return 1;
              }
              isAddDayA = originDestinations[fLenA].departure.date !== a.flights[fLenA].segments[fsLenA].originDestination.arrival.date;
              timeA = parseInt(tmpA[0]) * 60 + parseInt(tmpA[1]) + (isAddDayA ? 24 * 60 : 0);

              tmpB = b.flights[fLenB].segments[fsLenB].originDestination.arrival.time.split(':');
              if (tmpB.length < 2) {
                return 0;
              }
              isAddDayB = originDestinations[fLenB].departure.date !== b.flights[fLenB].segments[fsLenB].originDestination.arrival.date;
              timeB = parseInt(tmpB[0]) * 60 + parseInt(tmpB[1]) + (isAddDayB ? 24 * 60 : 0);

              return timeA < timeB ? 1 : -1;
          }
          break;
      }
    });
    return result;
  }

  groupOffersByFlights(offers: any[]) {
    const groupedOffersMap = new Map<string, any[]>();

    for (const offer of offers) {
      const hash = offer.flights.map(flight => {
        return flight.segments.map(segment => {
          const { marketingCarrier, originDestination } = segment;
          const { airlineID, flightNumber } = marketingCarrier;
          const { date, time } = originDestination.departure;

          return `${airlineID}${flightNumber}${date}${time}`;
        });
      }).flat().join('');

      const groupedOffersByKey = groupedOffersMap.get(hash);

      if (!groupedOffersByKey) {
        groupedOffersMap.set(hash, [offer]);
      } else {
        groupedOffersByKey.push(offer);
        groupedOffersMap.set(hash, groupedOffersByKey);
      }
    }

    return Array.from(groupedOffersMap.values());
  }

  public groupServicesBySegment(services, segments) {
    const servicesPerTraveler = {};
    const groupServiceByTravelerRef = {};
    services?.map(service => {
      const travelerReferences = service.travelerReferences.split(' ');
      if (travelerReferences?.length > 0) {
        travelerReferences.map(travelerRef => {
          service['quantity'] = 1;
          this.safePush(groupServiceByTravelerRef, travelerRef, service);
        });
      }
    });
    Object.keys(groupServiceByTravelerRef).map(passenger => {
      const destinationData = {
        entireTrip: [],
        perLeg: {},
        perSegment: {}
      };
      if (Array.isArray(groupServiceByTravelerRef[passenger])) {
        groupServiceByTravelerRef[passenger].map(service => this.splitServiceByDestinationType(service, segments, destinationData));
      }
      servicesPerTraveler[passenger] = destinationData;
    });


    return {
      servicesPerTraveler,
    };
  }

  splitServiceByDestinationType(service, segments, destinationData) {
    const segmentRefs = service.segmentReferences.split(' ');
    if (segments?.length === 1 || segmentRefs?.length === segments?.length) {
      destinationData.entireTrip.push(service);
    } else {
      if (segmentRefs.length > 1) {
        const firstSegment = segments.find(segment => segment.segmentID === segmentRefs[0]);
        const secondSegment = segments.find(segment => segment.segmentID === segmentRefs[segmentRefs.length - 1]);
        if (firstSegment && secondSegment) {
          if (firstSegment.originDestination.departure.airportCode !== secondSegment.originDestination.arrival.airportCode &&
              firstSegment.originDestination.departure.airportCode !== secondSegment.originDestination.departure.airportCode) {
            const way = firstSegment.originDestination.departure.airportCode + '-' + secondSegment.originDestination.arrival.airportCode;
            this.safePush(destinationData.perLeg, way, service);
          } else {
            const firstWay = firstSegment.originDestination.departure.airportCode + '-' + firstSegment.originDestination.arrival.airportCode;
            const firstService = Object.assign({}, service);
            firstService.segmentReferences = segmentRefs[0];
            this.safePush(destinationData.perSegment, firstWay, firstService);

            const secondWay = secondSegment.originDestination.departure.airportCode + '-' + secondSegment.originDestination.arrival.airportCode;
            const secondService = Object.assign({}, service);
            secondService.segmentReferences =  segmentRefs[1];
            this.safePush(destinationData.perSegment, secondWay, secondService);
          }
        } else {
          destinationData.entireTrip.push(service);
        }
      } else {
        const segment = segments.find(segment => segment.segmentID === segmentRefs[0]);
        if (segment) {
          const way = segment.originDestination.departure.airportCode + '-' + segment.originDestination.arrival.airportCode;
          this.safePush(destinationData.perSegment, way, service);
        }
      }
    }
  }

  safePush(obj, key, value) {
    if (obj[key] && Array.isArray(obj[key])){
      obj[key].push(value);
    } else {
      obj[key] = [value];
    }
  }

  private getAllOfferCabins(offer): string {
    let cabins = [];
    offer.flights.map(flight => {
      flight.segments.map(segment => {
        if (segment && segment.detail
          && segment.detail.classOfService
          && segment.detail.classOfService.cabinDesignator) {
          cabins.push(segment.detail.classOfService.cabinDesignator);
        }
      });
    });
    return cabins.join('|');
  }

  private getAllOfferFares(offer): string {
    let fares = [];
    offer.flights.map(flight => {
      flight.segments.map( segment => {
        if (segment && segment.detail &&
          segment.detail.classOfService &&
          segment.detail.classOfService.fare &&
          segment.detail.classOfService.fare.marketingName ) {
          fares.push(segment.detail.classOfService.fare.marketingName);
        }
      });
    });
    return fares.join('|');
  }

  public prepareFareInfo(offerCandidate, forSegment = false, forAirShoping = false) {
    if (!offerCandidate) {
      return [];
    }
    let fareNames = [];
    if (offerCandidate.disclosures && offerCandidate.disclosures[0] && offerCandidate.disclosures[0].listKey) {
      const uniqueDisclosures = Array.from(new Set(offerCandidate.disclosures
        .map(disclosure => disclosure.listKey)))
        .map(listKey => {
          return {
            listKey: listKey,
            descriptions: offerCandidate.disclosures.find(s => s.listKey === listKey).descriptions
          };
        });

      fareNames = offerCandidate.flights.map((flight) => {
        const temp = flight.segments?.map((segment, segIdx) => {
          // if (segment.detail.classOfService.disclosureRefs && segment.detail.classOfService.disclosureRefs.length) {
          return {
            fareStandard: forAirShoping
              ? `${segment.detail.classOfService.fare.priceClassName
                ? segment.detail.classOfService.fare.priceClassName
                : ''
              }${segment.detail.classOfService.fare.priceClassName && segment.detail.classOfService.fare.marketingName
                ? ' | '
                : ''
              }${segment.detail.classOfService.fare.marketingName || ''}`
              : segment.detail.classOfService.fare.marketingName,
            flightNumber: segment.flightNumber || '',
            fareBasisCode: segment.detail.classOfService.fare.basisCode,
            disclosureRefs: segment.detail.classOfService.disclosureRefs,
            disclosures: [],
          };
          // }
          // return '';
        });
        return forSegment ? temp : this.getUniqueFlightSegmentsByFareName(temp);
      });

      fareNames.map((flight, flightIndex) => {
        flight?.map((segment, segmentIndex) => {
          if (segment.disclosureRefs) {
            let tempDisclosures = [];
            Array.from(new Set(segment.disclosureRefs)).map(disclosure => {
              uniqueDisclosures.map(item => {
                if (item.descriptions && disclosure === item.listKey) {
                  tempDisclosures = [...tempDisclosures, ...item.descriptions];
                }
              });
            });
            fareNames[flightIndex][segmentIndex].disclosures = tempDisclosures;
          }
        });
      });
    } else {
      fareNames = offerCandidate.flights
        ?.map(flight => flight.segments
          ?.map((segment) => {
            let disclosures = [];
            if (segment.detail.classOfService.disclosures &&
              Object.keys(segment.detail.classOfService.disclosures)) {
              disclosures.push(segment.detail.classOfService.disclosures);
            }
            return {
              fareStandard: forAirShoping ? `${segment.detail.classOfService.fare.priceClassName
                ? segment.detail.classOfService.fare.priceClassName
                : ''
                }${segment.detail.classOfService.fare.priceClassName && segment.detail.classOfService.fare.marketingName
                ? ' | '
                : ''}${segment.detail.classOfService.fare.marketingName || ''}`
                : segment.detail.classOfService.fare.marketingName,
              disclosureRefs: segment.detail.classOfService.disclosureRefs,
              flightNumber: segment.flightNumber || '',
              fareBasisCode: segment.detail.classOfService.fare.basisCode,
              disclosures
            };
          }));
    }
    return fareNames;
  }

  private formatFlightNumber(flightNumber, owner) {
    let withoutProvider = flightNumber.replace(owner, '');
    return owner + withoutProvider.padStart(4, '0');
  }

  private getUniqueFlightSegmentsByFareName(segments: any[]) {
    return Array.from(new Set(segments
      .map(item => item.fareStandard)))
      .map(fareStandard => {
        return {
          fareStandard: fareStandard,
          flightNumber: segments.find(s => s.fareStandard === fareStandard).flightNumber,
          fareBasisCode: segments.find(s => s.fareStandard === fareStandard).fareBasisCode,
          disclosures: segments.find(s => s.fareStandard === fareStandard).disclosures,
          disclosureRefs: segments.find(s => s.fareStandard === fareStandard).disclosureRefs,
        };
      });
  }

  extractFareType(offer): CommonFareType | null {
    const fareTypes: CommonFareType[] = [];
    if (offer && offer.flights) {
      offer.flights.map(flight => {
        flight.segments.map(segment => {
          const fareType = segment.detail.classOfService.fare.type;
          const fareCode = segment.detail.classOfService.fare.code;
          if (fareType && fareCode) {
            fareTypes.push({fareType, fareCode});
          }
        });
      });
    }
    const isPrivate = fareTypes.filter((fareType) => fareType.fareType === 'Private');

    return (fareTypes.length) ? (isPrivate.length ? isPrivate[0] : fareTypes[0]) : null;
  }

  prepareFareTypesInfo(commonFareType: CommonFareType, fareTypesCategories: FareTypesCategory[]): PrepareFareTypeInfo | null {
    if (commonFareType) {
      let fareTooltipText = commonFareType.fareCode ? `${commonFareType.fareCode} - ${commonFareType.fareType}` : commonFareType.fareType;
      let fareTypesCategory: FareTypesCategory = fareTypesCategories.filter((fareTypesCategory: FareTypesCategory) => {
        return commonFareType.fareType.toLowerCase().includes(fareTypesCategory.groupName.toLowerCase());
      }).pop();

      if (!fareTypesCategory) {
        fareTypesCategory = fareTypesCategories.find((item: FareTypesCategory) => item.groupName === Dictionary.fareTypeGroup.misc);
      }
      return {commonFareType, fareTooltipText, fareTypesCategory};
    }
    return null;
  }

  setOfferUrl(offer, urlData, selectedCorporate) {
    const queryParams = {};
    if (selectedCorporate) {
      queryParams['corporate_id'] = selectedCorporate.id;
    }
    if (this.presetTitle) {
      queryParams['presetTitle'] = this.presetTitle;
    }
    const tree = this.router.createUrlTree([`/offer/${offer.offerID}`], {queryParams});
    return this.serializer.serialize(tree);
  }

  onTelInputObject(telInputObjects, selectedCountry = '') {
    let language = navigator.language;
    let iso = language.split('-').pop();
    let value = selectedCountry || this.ls.settings?.telCountryNumber?.iso || iso;
    if (value) {
      let priority = 9999;
      intlTelInputGlobals.getCountryData().map(country => {
        if ((country.iso2 === value?.toLowerCase() || country.dialCode === value) && country.priority < priority) {
          telInputObjects.setCountry(country.iso2);
          priority = country.priority;
        }
      });
    }
  }

  isNumber(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  public checkPrivateMode(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const yes = () => resolve(true); // is in private mode
      const not = () => resolve(false); // not in private mode

      function detectChromeOpera(): boolean {
        // https://developers.google.com/web/updates/2017/08/estimating-available-storage-space
        const isChromeOpera = /(?=.*(opera|chrome)).*/i.test(navigator.userAgent) && navigator.storage?.estimate;
        if (isChromeOpera) {
          navigator.storage.estimate().then(({ quota }) => {
            quota < 120000000 ? yes() : not();
          });
        }
        return !!isChromeOpera;
      }

      function detectFirefox(): boolean {
        const isMozillaFirefox = 'MozAppearance' in document.documentElement.style;
        if (isMozillaFirefox) {
          if (indexedDB == null) yes();
          else {
            const db = indexedDB.open('inPrivate');
            db.onsuccess = not;
            db.onerror = yes;
          }
        }
        return isMozillaFirefox;
      }

      function detectSafari(): boolean {
        const isSafari = navigator.userAgent.match(/Version\/([0-9\._]+).*Safari/);
        if (isSafari) {
          const testLocalStorage = () => {
            try {
              if (localStorage.length) not();
              else {
                localStorage.setItem('inPrivate', '0');
                localStorage.removeItem('inPrivate');
                not();
              }
            } catch (_) {
              // Safari only enables cookie in private mode
              // if cookie is disabled, then all client side storage is disabled
              // if all client side storage is disabled, then there is no point
              // in using private mode
              navigator.cookieEnabled ? yes() : not();
            }
            return true;
          };

          const version = parseInt(isSafari[1], 10);
          if (version < 11) return testLocalStorage();
          try {
            (window as any).openDatabase(null, null, null, null);
            not();
          } catch (_) {
            yes();
          }
        }
        return !!isSafari;
      }

      function detectEdgeIE10(): boolean {
        const isEdgeIE10 = !window.indexedDB && (window.PointerEvent || window.MSPointerEvent);
        if (isEdgeIE10) yes();
        return !!isEdgeIE10;
      }

      // when a browser is detected, it runs tests for that browser
      // and skips pointless testing for other browsers.
      if (detectChromeOpera()) return;
      if (detectFirefox()) return;
      if (detectSafari()) return;
      if (detectEdgeIE10()) return;

      // default navigation mode
      return not();
    });
  }

  isCriticalError(error): boolean {
    let isCritical = true;

    if (error?.status < 400) {
      isCritical = false;
    }
    if (error?.name === 'AbortError' || error?.error?.code === ERROR_CODES.OFFER_EXPIRED || error?.status === 503) {
      isCritical = false;
    }

    return isCritical;
  }

  recursiveDeepCopy(obj) {
    return Object.keys(obj).reduce((v, d) => Object.assign(v, {
      [d]: (obj[d].constructor === Object) ? this.recursiveDeepCopy(obj[d]) : obj[d]
    }), {});
  }

  setFocusToSelect2SearchField() {
    const searchField = document.querySelector('.select2-search__field') as HTMLElement;
    if (searchField) {
      searchField.focus();
    }
  }

  saveTelCountryNumber($event) {
    if (!this.ls.settings?.telCountryNumber) {
      let telCountryNumber = {iso: $event.iso2, dialCode: $event.dialCode};
      let settings = this.ls.settings;
      settings.telCountryNumber = telCountryNumber;
      this.ls.settings = settings;
    }
  }

  capitalizeFirstLetter(str) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : null;
  }

  getTitles(titles: {[key: string]: string}[]): string[] {
    const titleKeysArr = [];
    if (titles) {
      titles.forEach(obj => {
        Object.keys(obj).forEach(key => {
          titleKeysArr.push(key);
        });
      });
      return titleKeysArr;
    } else {
      return AVAILABLE_TITLES;
    }
  }

  prepareBirthdateValidation(offer, preset = null) {
    const minMaxDateOfBirth = {};
    const passengersTypes = Array.from(new Set(offer.passengers.map(passenger => passenger.passengerType))) as string[];

    const provider = ProviderAgesScheme[offer.owner] || ProviderAgesScheme.default;
    passengersTypes.forEach(passenger => {
      let presetPassengerType;
      if (preset) {
        const travelerRelation = preset.travelers.find(traveler => traveler.travelerType === passenger);
        if (travelerRelation && travelerRelation.defaultType !== passenger) {
          presetPassengerType = travelerRelation.defaultType;
        }
      }

      const minMaxYears = provider[presetPassengerType] || provider[passenger] || FullMinMaxAgeRange;
      minMaxDateOfBirth[passenger] = this.dateService.getMinMaxDate(false, ...minMaxYears);
    });

    return minMaxDateOfBirth;
  }

  removeFalsyKeys(obj) {
    if (obj) {
      Object.entries(obj).forEach(([code, value]) => {
        if (!value) {
          delete obj[code];
        }
      });
    }
  }

  updateService(service: any): any {
    if (service.bookingInstructions?.instructions?.length) {
      let placeHolder = service.bookingInstructions.placeHolder;
      let varkeys = placeHolder.match(/%(\w+)%/g);
      let values: { [key: string]: string } = {};

      // Fill dictionary with current values from instructions
      service.bookingInstructions.instructions.forEach(instruction => {
        let value = instruction.value || '';
        values[instruction.varkey] = value;
      });

      // Reconstruct the text using the order from placeHolder
      let updatedText = placeHolder;
      varkeys?.forEach(key => {
        let cleanKey = key.replace(/%/g, '');
        updatedText = updatedText.replace(key, values[cleanKey] || '');
      });

      updatedText = updatedText.replace(/\\s\?/g, '').trim();

      service.text = updatedText;
    }
    return service;
  }

}
