<ng-container *ngIf="isModal">
  <div *ngIf="paymentWarnings?.length" class="mb-3 mt-3">
    <app-show-warnings [warnings]="paymentWarnings"></app-show-warnings>
  </div>

  <div class="mt-3">
    <error-alert-block
      [error]="paymentError"
    ></error-alert-block>
  </div>

  <div *ngIf="step == 'payment'"
       [formGroup]="service.form"
       class="sentry-mask mt-4 needs-validation payment-form">
     <div *ngIf="showTotalPrice"
          class="payment-info__price"
          [class.payment-info__price_primary]="totalPrice > 0"
          [class.payment-info__price_danger]="totalPrice < 0"
     >
       <div class="mt-4 changed-price total-amount">
         <span>To be paid:</span> <b>{{totalPrice | number :'1.2-2' }}</b> {{currency}}
       </div>
   </div>
    <div class="form-row" *ngIf="paymentMethodsExists">
      <div class="payment-type col-md-auto"
           [class.form-group-xl]="!(warningsPerPaymentMethod.card.length && service.form.get('method').value === 'card')"
           *ngIf="settings">
        <div class='pt-4'><h3>Payment method</h3></div>
        <div
          *ngIf="settings.none"
          class="form-check form-check-inline"
        >
          <input class="form-check-input" id="r1" type="radio" value="none" formControlName="method"
                 [validate]="validateTriggered" (change)="onPaymentMethodChange($event.target.value)">
          <label class="form-check-label" for="r1">
            None
          </label>
        </div>
        <div
          *ngIf="settings.agencyCash"
          class="form-check form-check-inline"
        >
          <input class="form-check-input" id="r4" type="radio" value="agencyCash" formControlName="method"
                [validate]="validateTriggered" (change)="onPaymentMethodChange($event.target.value)">
          <label class="form-check-label" for="r4">
            Agency Cash
          </label>
        </div>
        <div
          *ngIf="settings.agencyCard"
          class="form-check form-check-inline"
        >
          <input class="form-check-input" id="r2" type="radio" value="agencyCard" formControlName="method"
                 [validate]="validateTriggered" (change)="onPaymentMethodChange($event.target.value)">
          <label class="form-check-label" for="r2">
            Agency Card
          </label>
        </div>
        <div
          *ngIf="settings.card"
          class="form-check form-check-inline"
        >
          <input class="form-check-input" id="r3" type="radio" value="card" formControlName="method"
                 [validate]="validateTriggered" (change)="onPaymentMethodChange($event.target.value)">
          <label class="form-check-label" for="r3">
            Credit/Debit Card
          </label>
        </div>
      </div>
      <ng-container *ngIf="warningsPerPaymentMethod.card.length && service.form.get('method').value === 'card'">
        <div class="alert alert-warning p-3 my-4 rounded"
             *ngFor="let cardWarning of warningsPerPaymentMethod.card">
          {{cardWarning}}
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="!hasCustomPayment">
      <div class="form-row">
        <div class="form-group col-md-12">
          <ng-container *ngIf="!paymentMethodsExists">You don't have enabled payment options, please contact AirGateway.
          </ng-container>
          <ng-container *ngIf="service.form.get('method').value === 'agencyCard'">You've selected <b>Agency Card</b>.
          </ng-container>
          <ng-container *ngIf="service.form.get('method').value === 'agencyCash'">You've selected <b>Agency Cash</b>.
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container [ngTemplateOutlet]="cardFrom"></ng-container>
  </div>
</ng-container>

<ng-container *ngIf="!isModal" [formGroup]="service.form">
  <div class="info-card mb-3" *ngIf="settings.none">
    <div class="info-card-body">
      <div class="blue-container"></div>
      <div class="info-container">
        <div class="form-check form-check-inline gap-15">
          <input class="form-check-input" id="r1" type="radio" value="none" formControlName="method"
                 [validate]="validateTriggered" (change)="onPaymentMethodChange($event.target.value)">
          <label class="form-check-label" for="r1">
            None
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="info-card mb-3" *ngIf="settings.agencyCash">
    <div class="info-card-body">
      <div class="blue-container"></div>
      <div class="info-container">
        <div class="form-check form-check-inline gap-15">
          <input class="form-check-input" id="r4" type="radio" value="agencyCash" formControlName="method"
                 [validate]="validateTriggered" (change)="onPaymentMethodChange($event.target.value)">
          <label class="form-check-label" for="r4">
            Agency Cash
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="info-card mb-3" *ngIf="settings.agencyCard">
    <div class="info-card-body">
      <div class="blue-container"></div>
      <div class="info-container">
        <div class="form-check form-check-inline gap-15">
          <input class="form-check-input" id="r2" type="radio" value="agencyCard" formControlName="method"
                 [validate]="validateTriggered" (change)="onPaymentMethodChange($event.target.value)">
          <label class="form-check-label" for="r2">
            Agency Card
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="info-card mb-3" *ngIf="settings.card">
    <div class="info-card-body">
      <div class="blue-container"></div>
      <div class="info-container">
        <div *ngIf="settings.card"
             class="form-check form-check-inline gap-15">
          <input class="form-check-input" id="r3" type="radio" value="card" formControlName="method"
                 [validate]="validateTriggered" (change)="onPaymentMethodChange($event.target.value)">
          <label class="form-check-label" for="r3">
            Credit/Debit Card
          </label>
        </div>
        <div class="card-form" *ngIf="hasCustomPayment">
          <ng-container [ngTemplateOutlet]="cardFrom"></ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #cardFrom>
  <ng-container [formGroup]="service.form">
    <ng-container *ngIf="hasCustomPayment">
      <h4>Card</h4>
      <div class="form-row">
        <div class="form-group-sm" [ngClass]="showSurchargeType ? 'col-md-3' : 'col-md-4'">
          <div class="col p-0">
            <label>Card Type <span class="text-primary"><b>*</b></span></label>
            <select [validate]="service.form.get('cardType').dirty || validateTriggered"
                    formControlName="cardType"
                    class="form-control">
              <option value="CC">Credit</option>
              <option value="DB">Debit</option>
            </select>
          </div>
        </div>
        <div [ngClass]="showSurchargeType ? 'col-md-9' : 'col-md-8'">
          <div class="form-row">
            <div class="form-group-sm" [ngClass]="showSurchargeType ? 'col-md-4' : 'col-md-6'">
              <label>Card Code <span class="text-primary"><b>*</b></span></label>
              <select [validate]="service.form.get('cardCode').dirty || validateTriggered"
                      formControlName="cardCode"
                      class="form-control"
                      (change)="setCardType($event)">
                <option *ngFor="let code of allowedCardCodes | keyvalue" [value]="code.key">
                  {{ code.value }}
                </option>
              </select>
            </div>
            <ng-container *ngIf='showSurchargeType'>
              <div class="form-group-sm col" *ngIf="service.form.get('surchargeType') as surchargeType">
                <label>Surcharge Type <span class="text-primary"><b>*</b></span></label>
                <div class="d-flex align-items-center">
                  <select [validate]="surchargeType.dirty || validateTriggered"
                          formControlName="surchargeType"
                          class="form-control"
                          (change)="setSurchargeType($event)">
                    <option hidden selected value="">Select surcharge type ...</option>
                    <option *ngFor="let surchargeType of surchargeTypes" [value]="surchargeType">{{surchargeType}}
                  </select>
                  <span *ngIf="surchargesInfo" class="surcharges-info">+{{surchargesInfo}}</span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group-sm col-md-4">
          <label>Card Number <span class="text-primary"><b>*</b></span></label>
          <input [validate]="service.form.get('cardNumber').dirty || validateTriggered"
                 type="text"
                 formControlName="cardNumber"
                 placeholder="1234567890123456"
                 class="form-control"/>
        </div>

        <div class="col-md-5">
          <div class="form-row">
            <div class="form-group-sm col-md-6" *ngIf="service.form.get('seriesCode') as seriesCode">
              <label>Series code (CVC)</label>
              <div class="form-row">
                <div class="col-md-8">
                  <input [validate]="seriesCode.dirty || validateTriggered"
                         type="text"
                         formControlName="seriesCode"
                         class="form-control"
                         placeholder="XXX..."
                  />
                </div>
              </div>
            </div>
            <div class="form-group-sm col-md-6" *ngIf="service.form.get('approvalCode') as approvalCode">
              <label>Approval code</label>
              <div class="form-row">
                <div class="col-md-8">
                  <input [validate]="approvalCode.dirty || validateTriggered"
                         type="text"
                         formControlName="approvalCode"
                         class="form-control"
                         placeholder="XX..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-4 form-group-xl">
          <label>Expiration date <span class="text-primary"><b>*</b></span></label>
          <div class="form-group-sm form-row">
            <div class="col">
              <select [validate]="service.form.get('expiration_month').dirty || validateTriggered"
                      formControlName="expiration_month"
                      class="form-control">
                <option hidden selected value="">MM</option>
                <option *ngFor="let month of monthsList"
                        [disabled]="service.form.get('expiration_year').value === currentYear.toString() && (month | number) < currentMonth"
                        [value]="month">
                  {{month }}
              </select>
            </div>
            <div class="col">
              <select [validate]="service.form.get('expiration_year').dirty || validateTriggered"
                      formControlName="expiration_year"
                      class="form-control"
                      (change)="onYearSelect($event.target.value)">
                <option hidden selected value="">YY</option>
                <option *ngFor="let year of yearsList" [value]="year">{{year}}
              </select>
            </div>
          </div>
        </div>
      </div>

      <h4>Cardholder</h4>
      <div class="form-row">
        <div class="form-group-sm col-md-2">
          <label>Title</label>
          <select [validate]="service.form.get('cardHolderTitle').dirty || validateTriggered"
                  class="form-control"
                  name="cardHolderTitle"
                  id="cardHolderTitle"
                  formControlName="cardHolderTitle">
            <option value="MR">MR</option>
            <option value="MS">MS</option>
          </select>
        </div>

        <div class="form-group-sm col-md-5">
          <label>Name</label>
          <input [validate]="service.form.get('cardHolderName').dirty || validateTriggered"
                 type="text"
                 formControlName="cardHolderName"
                 class="form-control"
                 placeholder="Alex"/>
        </div>
        <div class="form-group-sm col-md-5">
          <label>Surname</label>
          <input [validate]="service.form.get('cardHolderSurname').dirty || validateTriggered"
                 type="text"
                 formControlName="cardHolderSurname"
                 class="form-control"
                 placeholder="Crow"/>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group-xl col-md-6">
          <label>Email</label>
          <input [validate]="service.form.get('cardHolderEmail').dirty || validateTriggered"
                 type="text"
                 formControlName="cardHolderEmail"
                 class="form-control"
                 placeholder="web@example.com"/>
        </div>

        <div class="form-group-xl col-md-6"
             formGroupName="phone"
             *ngIf="service.form.get('phone.number') as phoneNumber">
          <label>Phone</label>
          <input [validate]="phoneNumber.dirty || validateTriggered"
                 (countryChange)="onCountryChange($event)"
                 (intlTelInputObject)="telInputObject($event)"
                 (ng2TelOutput)="getNumber($event)"
                 [ng2TelInputOptions]="helpers.defaultTelInputOptions"
                 class="form-control"
                 formControlName="number"
                 ng2TelInput>
        </div>
      </div>

      <h4>Address</h4>
      <div class="form-row">
        <div class="form-group-sm col-md-4">
          <label>Country</label>
          <select
            [validate]="service.form.get('countryCode').dirty || validateTriggered"
            class="form-control"
            formControlName="countryCode"
          >
            <option value="">Select country ...</option>
            <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
            </option>
          </select>
        </div>

        <div class="form-group-sm col-md-4">
          <label>City</label>
          <input [validate]="service.form.get('cityName').dirty || validateTriggered"
                 type="text"
                 formControlName="cityName"
                 class="form-control"
                 placeholder="Madrid"/>
        </div>

        <div class="form-group-sm col-md-4">
          <label>Postal Code</label>
          <input [validate]="service.form.get('postalCode').dirty || validateTriggered"
                 type="text"
                 formControlName="postalCode"
                 class="form-control"
                 placeholder="28001"/>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6">
          <label class="state-label"
                 placement="right"
                 ngbTooltip="This is required for AirCanada, airlines expect a 2 digit number related to postal code">
            State/Province
          </label>
          <input [validate]="service.form.get('stateProv').dirty || validateTriggered"
                 type="text"
                 formControlName="stateProv"
                 class="form-control"
                 placeholder="28"/>
        </div>
        <div class="form-group col-md-6">
          <label>Street</label>
          <input [validate]="service.form.get('street1').dirty || validateTriggered"
                 type="text"
                 formControlName="street1"
                 class="form-control"
                 placeholder="Gran Via"/>
        </div>
      </div>

      <div class="form-row content-center">
        <div><small><span class="text-primary"><b>*</b></span> Required fields</small></div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
